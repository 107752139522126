export const STATISTICS_GET_DATA = '[Statistics] get data'
export const STATISTICS_GET_DATA_SUCCESS = '[Statistics] get data success'
export const STATISTICS_GET_DATA_FAILURE = '[Statistics] get data failure'
export const STATISTICS_UPDATE_DATA = '[Statistics] update data'

export const STATISTICS_UPDATE_FILTERS = '[Statistics] update filters'

/////// user actions

export const statisticsGetData = (filters) => ({
    type: STATISTICS_GET_DATA,
    payload: filters
});

export const statisticsUpdateFilters = (newFilters) => ({
    type: STATISTICS_UPDATE_FILTERS,
    payload: newFilters
});

/////////// system actions

export const statisticsUpdateData = (statisticsData) => ({
    type: STATISTICS_UPDATE_DATA,
    payload: statisticsData
});