import { UPDATE_LOGIN } from './login.actions'

const initLogin = {
    loggedIn: false,
    user: null
}

export function loginReducer(state = initLogin, action) {
    switch(action.type) {
        case UPDATE_LOGIN:
            return {...state, loggedIn: action.payload.loggedIn, user: action.payload.user}
        default:
            return state
    }
}