import { STATISTICS_GET_DATA, STATISTICS_GET_DATA_SUCCESS, STATISTICS_GET_DATA_FAILURE,
    statisticsUpdateData} from './statistics.actions'
import { apiRequest } from '../api/api.action'
import { APP_DB } from './../../constants'
import getUnixTime from 'date-fns/getUnixTime'

// filter object looks like this
// null value is no filter
// eslint-disable-next-line
const exampleFilter = {
    startDate: null,
    endDate: null,
    acknowledged: null,
    verified: null,
    sentVia: null
}
const statisticsGetDataSideEffect = (store) => next => action => {
    next(action)
    if (action.type === STATISTICS_GET_DATA) {

        const filter = action.payload

        store.dispatch(apiRequest(
            '/api/alerts/find?filter='+JSON.stringify(filter),
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'GET',
            },
            STATISTICS_GET_DATA_SUCCESS, STATISTICS_GET_DATA_FAILURE)
        )
    }
}


const statisticsGetDataProcessor = (store) => next => action => {
    next(action)
    if (action.type === STATISTICS_GET_DATA_SUCCESS) {
        store.dispatch(statisticsUpdateData(action.payload.docs))
    }
}


export const statisticsMiddleware = [
    statisticsGetDataSideEffect,
    statisticsGetDataProcessor
]