export const FILE_UPLOAD_IMAGES = '[File Upload] images'
export const FILE_UPLOAD_IMAGES_SUCCESS = '[File Upload] images success'
export const FILE_UPLOAD_IMAGES_FAILURE = '[File Upload] images failure'

export const FILE_UPLOAD_ATTACHMENTS = '[File Upload] attachments'
export const FILE_UPLOAD_ATTACHMENTS_SUCCESS = '[File Upload] attachments success'
export const FILE_UPLOAD_ATTACHMENTS_FAILURE = '[File Upload] attachments failure'

export const FILE_UPLOAD_GET_ALERT = '[File Upload] get alert'
export const FILE_UPLOAD_GET_ALERT_SUCCESS = '[File Upload] get alert success'
export const FILE_UPLOAD_GET_ALERT_FAILURE = '[File Upload] get alert failure'

export const FILE_UPLOAD_REQUEST_URL = '[File Upload] request url'
export const FILE_UPLOAD_REQUEST_URL_SUCCESS = '[File Upload] request url success'

export const FILE_UPLOAD_SEND = '[File Upload] send'
export const FILE_UPLOAD_SEND_SUCCESS = '[File Upload] send success'
export const FILE_UPLOAD_SEND_FAILURE = '[File Upload] send failure'

export const FILE_UPLOAD_UPDATE_CURRENT_UPLOAD = '[File Upload] update current upload'
export const FILE_UPLOAD_CLEAR_CURRENT_UPLOAD = '[File Upload] clear current upload'

////////////// user actions

export const fileUploadImages = (imageFiles) => ({
    type: FILE_UPLOAD_IMAGES,
    payload: imageFiles
});

export const fileUploadAttachments = (alert, attachmentFiles, type) => ({
    type: FILE_UPLOAD_ATTACHMENTS,
    payload: {
        alert,
        files: attachmentFiles,
        type
    }
});

export const fileUploadClearCurrentFileUploads = () => ({
    type: FILE_UPLOAD_CLEAR_CURRENT_UPLOAD,
});


////////////// system actions


export const fileUploadUpdateCurrentFileUploads = ( uploadId, { alert, file, fileName, category, type, S3Url, S3Path, complete, successful }) => ({
    type: FILE_UPLOAD_UPDATE_CURRENT_UPLOAD,
    payload: { 
        uploadId,
        upload: { alert, file, fileName, category, type, S3Url, S3Path, complete, successful }
    }
});

export const fileUploadGetAlert = (uploadId) => ({
    type: FILE_UPLOAD_GET_ALERT,
    payload: uploadId
});

export const fileUploadRequestUrl = (uploadId) => ({
    type: FILE_UPLOAD_REQUEST_URL,
    payload: uploadId
});

export const fileUploadSend = (uploadId) => ({
    type: FILE_UPLOAD_SEND,
    payload: uploadId
});