import { FORM_UPDATE_LIST, UPDATE_LAST_FORM_ID, FORM_UPDATE_NEW_FORM_TO_CREATE, FORM_UPDATE_NETWORK_REQUEST_COMPLETE_FLAG, FORM_DELETE_CONFIRM_MODAL } from './form.actions'

const initForms = {
    all: [],
    lastFormId: -1,
    newFormToCreate: null,
    successfulRequest: false, // used to navigate form form edit page to form list page when the update is completed successfully
    showDeleteFormConfirmModal:false,
    formToBeDeleted: ""
}

export function formReducer(state = initForms, action) {
    switch(action.type) {
        case FORM_UPDATE_LIST:
            return {...state, all: action.payload}
        case UPDATE_LAST_FORM_ID:
            return {...state, lastFormId: action.payload}
        case FORM_UPDATE_NEW_FORM_TO_CREATE:
            return {...state, newFormToCreate: action.payload}
        case FORM_UPDATE_NETWORK_REQUEST_COMPLETE_FLAG:
            return {...state, successfulRequest: action.payload}
        case FORM_DELETE_CONFIRM_MODAL:
            return {...state, showDeleteFormConfirmModal: action.payload.show, formToBeDeleted: action.payload.form}
        default:
            return state
    }
}