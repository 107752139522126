export const getGeo = (flGeoId) => {
    return (state) => {
        const mySource = state.geo.sources.find(source => source.id === flGeoId)
        if (typeof mySource === 'undefined') {
            throw new Error('Geo source id not found error: ' + flGeoId)
        } else {
            return mySource
        }
    }
}
