import { FILE_UPLOAD_IMAGES, FILE_UPLOAD_ATTACHMENTS, FILE_UPLOAD_GET_ALERT, FILE_UPLOAD_GET_ALERT_SUCCESS, FILE_UPLOAD_GET_ALERT_FAILURE, FILE_UPLOAD_REQUEST_URL, FILE_UPLOAD_REQUEST_URL_SUCCESS, FILE_UPLOAD_SEND,
    FILE_UPLOAD_SEND_SUCCESS, FILE_UPLOAD_SEND_FAILURE, 
    fileUploadGetAlert, fileUploadRequestUrl, fileUploadSend, fileUploadUpdateCurrentFileUploads } from './fileUpload.actions'
import { apiRequest } from './../api/api.action'
import { getCurrentFileUpload } from './fileUpload.selector'
import { FILE_UPLOAD_CATEGORY_IMAGES, FILE_UPLOAD_CATEGORY_ATTACHMENTS} from './../../constants'


export const fileUploadImages = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === FILE_UPLOAD_IMAGES) {
        action.payload.forEach(file => {
            const fileName = file.name
            const uploadId = generateUID()
            dispatch(fileUploadUpdateCurrentFileUploads( uploadId, { file, fileName, category: FILE_UPLOAD_CATEGORY_IMAGES })) // creates the file upload in the store
            dispatch(fileUploadGetAlert(uploadId))
        })
    }
}

export const fileUploadAttachments = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === FILE_UPLOAD_ATTACHMENTS) {
        const alert = action.payload.alert
        const type = action.payload.type
        action.payload.files.forEach(file => {
            const uploadId = generateUID()
            dispatch(fileUploadUpdateCurrentFileUploads( uploadId, { file, fileName: file.name, alert, category: FILE_UPLOAD_CATEGORY_ATTACHMENTS, type })) // creates the file upload in the store
            dispatch(fileUploadRequestUrl(uploadId))
        })
    }
}

export const fileUploadGetAlertSideEffect = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FILE_UPLOAD_GET_ALERT) {
        const uploadId = action.payload
        const uploadObject = getCurrentFileUpload(uploadId)(getState())

        // The image name should be in the format of {collect_time}_{0-5}
        const collectTime = uploadObject.fileName.split('_')[0]

        const body = { 
            selector:{ 
                type: "alert", 
                $or:[{
                    collect_time:{ $eq: collectTime.toString()}
                }]
            }, 
            sort: [{collect_time: "desc"}], 
            fields:[], 
            limit:10, 
            execution_stats: true 
        }
        dispatch(apiRequest(
            '/_find',
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(body)
            },
            FILE_UPLOAD_GET_ALERT_SUCCESS, FILE_UPLOAD_GET_ALERT_FAILURE,
            uploadId // success data passed on
        ))
    }
}

export const fileUploadGetAlertProcessor = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FILE_UPLOAD_GET_ALERT_SUCCESS) {
        const uploadId = action.meta.meta.successData
        const uploadObject = getCurrentFileUpload(uploadId)(getState())

        const alertsFound = action.payload.docs

        if (alertsFound.length > 0) {
            uploadObject.alert = alertsFound[0]
            dispatch(fileUploadUpdateCurrentFileUploads(uploadId, uploadObject))
            dispatch(fileUploadRequestUrl(uploadId))
        } else { // if find request finds no alerts
            dispatch({type: FILE_UPLOAD_GET_ALERT_FAILURE, meta: { meta: { onSuccess: uploadId}}})
        }
    }
    if (action.type === FILE_UPLOAD_GET_ALERT_FAILURE) {
        const uploadId = action.meta.meta.successData
        const uploadObject = getCurrentFileUpload(uploadId)(getState())

        uploadObject.complete = true
        uploadObject.successful = false
        dispatch(fileUploadUpdateCurrentFileUploads(uploadId, uploadObject))
    }
}


export const fileUploadRequestUrlSideEffect = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FILE_UPLOAD_REQUEST_URL) {
        const uploadId = action.payload
        const uploadObject = getCurrentFileUpload(uploadId)(getState())

        const body = {
            name: uploadObject.fileName,
            country: 'dev',
            type: uploadObject.file.type,
            cat: uploadObject.category
        }
        dispatch(apiRequest(
            '/api/upload',
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(body)
            },
            FILE_UPLOAD_REQUEST_URL_SUCCESS, FILE_UPLOAD_SEND_FAILURE,
            uploadId // success data passed on
        ))
    }
}

export const fileUploadRequestUrlProcessor = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FILE_UPLOAD_REQUEST_URL_SUCCESS) {
        const uploadId = action.meta.meta.successData
        const uploadObject = getCurrentFileUpload(uploadId)(getState())

        uploadObject.S3Url = action.payload.url
        uploadObject.S3Path = action.payload.key

        dispatch(fileUploadUpdateCurrentFileUploads(uploadId, uploadObject))
        dispatch(fileUploadSend(uploadId))
    }
    // FILE_UPLOAD_SEND_FAILURE is handled below in fileUploadSendProcessor() method
}

export const fileUploadSendSideEffect = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FILE_UPLOAD_SEND) {
        const uploadId = action.payload
        const uploadObject = getCurrentFileUpload(uploadId)(getState())

        dispatch(apiRequest(
            uploadObject.S3Url,
            {
                headers: {
                    'Content-Type': uploadObject.file.type
                },
                method: 'PUT',
                body: uploadObject.file
            },
            FILE_UPLOAD_SEND_SUCCESS, FILE_UPLOAD_SEND_FAILURE,
            uploadId // success data passed on
        ))
    }
}

export const fileUploadSendProcessor = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FILE_UPLOAD_SEND_SUCCESS) {
        const uploadId = action.meta.meta.successData
        const uploadObject = getCurrentFileUpload(uploadId)(getState())

        uploadObject.complete = true
        uploadObject.successful = true
        dispatch(fileUploadUpdateCurrentFileUploads(uploadId, uploadObject))
    }
    if (action.type === FILE_UPLOAD_SEND_FAILURE) {
        const uploadId = action.meta.meta.successData
        const uploadObject = getCurrentFileUpload(uploadId)(getState())

        uploadObject.complete = true
        uploadObject.successful = false
        dispatch(fileUploadUpdateCurrentFileUploads(uploadId, uploadObject))
    }
}


function generateUID() {
    var firstPart = (Math.random() * 46656) | 0;
    var secondPart = (Math.random() * 46656) | 0;
    firstPart = ("000" + firstPart.toString(36)).slice(-3);
    secondPart = ("000" + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
}


export const fileUploadMiddleware = [
    fileUploadImages,
    fileUploadAttachments,
    fileUploadGetAlertSideEffect,
    fileUploadGetAlertProcessor,
    fileUploadRequestUrlSideEffect,
    fileUploadRequestUrlProcessor,
    fileUploadSendSideEffect,
    fileUploadSendProcessor,
]