import { formEditNonExistentFormSelected } from './formEdit.actions'
import  store  from './../store'

export const getForm = (formId) => {
    return (state) => {
        const myForm = state.formList.all.find(form => form.id === formId)
        if (typeof myForm === 'undefined') {
            store.dispatch(formEditNonExistentFormSelected(formId))
        } else {
            return myForm
        }
    }
}
