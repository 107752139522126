import { FORM_CREATE_START, FORM_GET_UUID, FORM_ADD_NEW, formGetAll, formUpdateList, formDeleteShowConfirmModal,
    FORM_ADD_NEW_SUCCESS, FORM_ADD_NEW_FAILURE, FORM_UUID_SUCCESS, FORM_UUID_FAILURE, formGetUuid,
    formAddNew, FORM_GET_LAST_ID, FORM_GET_LAST_ID_SUCCESS, FORM_GET_LAST_ID_FAILURE,
    formGetLastId, updateLastFormId, createOrUpdateFormCountDoc, FORM_GET_ALL, FORM_GET_ALL_SUCCESS, FORM_GET_ALL_FAILURE,
    FORM_GEN_PROTO, FORM_GEN_PROTO_SUCCESS, FORM_COPY_FAILURE, FORM_UPDATE, FORM_UPDATE_SUCCESS, FORM_UPDATE_FAILURE, FORM_DELETE, FORM_DELETE_SUCCESS, FORM_DELETE_FAILURE, FORM_COPY, FORM_COPY_SUCCESS,
    formCreateStart, formUpdateNewFormToCreate, formUpdateNetworkRequestCompleteFlag, FORM_GEN_PROTO_FAILURE } from './form.actions'
import { apiRequest } from './../api/api.action'
import { APP_DB } from './../../constants'
var _ = require('lodash');

export const formCreateProcessor = (store) => next => action => {
    next(action)
    if (action.type === FORM_CREATE_START) {
        // save the form we are going to create later
        // save it into store so we can get it again later in the process
        store.dispatch(formUpdateNewFormToCreate(action.payload))
        next(formGetLastId())
    }
}

export const formGetLastIdSideEffect = (store) => next => action => {
    next(action)
    if (action.type === FORM_GET_LAST_ID) {
        store.dispatch(apiRequest(
            '/' + APP_DB + '/_design/db-app/_view/formsLastId',
            {
                headers: {
                    'Content-Type': 'application/json'
                },
            },
            FORM_GET_LAST_ID_SUCCESS, FORM_GET_LAST_ID_FAILURE)
        )
    }
}

// returns object like...
// {
//     "total_rows": 1,
//     "offset": 0,
//     "rows": [
//         {
//             "id": "e2bece7c5bf370af5e4df136de01099e",
//             "key": {
//                 "_id": "e2bece7c5bf370af5e4df136de01099e",
//                 "_rev": "22-0345bb0e56a1b0b7264fae0f5782273a",
//                 "type": "formCount",
//                 "form_last_id": 21
//             },
//             "value": null
//         }
//     ]
// }

export const formLastIdProcessor = (store) => next => action => {
    if (action.type === FORM_GET_LAST_ID_SUCCESS) {
        const rows = action.payload.rows
        if (rows.length === 0) {
            store.dispatch(createOrUpdateFormCountDoc())
        } else {
            const lastFormId = rows[0].key.form_last_id
            store.dispatch(updateLastFormId(lastFormId))
            store.dispatch(formGetUuid())
        }
    } else {
        next(action)
    }
}

export const formGetUuidSideEffect = (store) => next => action => {
    next(action)
    if (action.type === FORM_GET_UUID) {
        store.dispatch(apiRequest(
            '/_uuids',
            {
                headers: {
                    'Content-Type': 'application/json'
                },
            },
            FORM_UUID_SUCCESS, FORM_UUID_FAILURE)
        )
    }
}


export const formUuidProcessor = ({ dispatch, getState }) => next => action => {
    if (action.type === FORM_UUID_SUCCESS) {
        const incrementId = getState().formList.lastFormId + 1
        const newFormToCreate = _.cloneDeep(getState().formList.newFormToCreate) // get the form we saved into the store earlier
        newFormToCreate._id = action.payload.uuids[0]
        newFormToCreate.id = incrementId
        
        dispatch(formAddNew(newFormToCreate))
    } else {
        next(action)
    }
}



export const formAddNewSideEffect = (store) => next => action => {
    next(action)
    if (action.type === FORM_ADD_NEW) {
        store.dispatch(apiRequest(
            '/' + APP_DB + '/'+ action.payload._id,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(action.payload)
            },
            FORM_ADD_NEW_SUCCESS, FORM_ADD_NEW_FAILURE)
        )
    }
}


export const formGetAllAfterCreated = (store) => next => action => {
    next(action)
    if (action.type === FORM_ADD_NEW_SUCCESS) {
        store.dispatch(formGetAll())
    }
}


export const formGetAllSideEffect = (store) => next => action => {
    next(action)
    if (action.type === FORM_GET_ALL) {
        store.dispatch(apiRequest(
            '/' + APP_DB + '/_design/db-app/_view/showForms',
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            },
            FORM_GET_ALL_SUCCESS, FORM_GET_ALL_FAILURE)
        )
    }
}

export const sendFormListToStore = (store) => next => action => {
    next(action)
    if (action.type === FORM_GET_ALL_SUCCESS) {
        store.dispatch(formUpdateList(action.payload.rows))
        // also store.dispatch manually update last form id
    }
}

export const formUpdateSideEffect = (store) => next => action => {
    next(action)
    if (action.type === FORM_UPDATE) {
        const form = action.payload
        store.dispatch(apiRequest(
            '/api/proto',
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(form)
            },
            FORM_UPDATE_SUCCESS, FORM_UPDATE_FAILURE)
        )
    }
}

export const formGenProtoSideEffect = (store) => next => action => {
    next(action)
    if (action.type === FORM_GEN_PROTO) {
        const form = action.payload
        store.dispatch(apiRequest(
            '/api/proto',
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(form)
            },
            FORM_GEN_PROTO_SUCCESS, FORM_GEN_PROTO_FAILURE)
        )
    }
}

export const formUpdateProcessor = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === FORM_UPDATE_SUCCESS) {
        dispatch(formUpdateNetworkRequestCompleteFlag(true))
        // dispatch(formGetAll())
    }
}

export const formDeleteSideEffect = (store) => next => action => {
    next(action)
    if (action.type === FORM_DELETE) {
        const form = action.payload
        store.dispatch(apiRequest(
            '/' + APP_DB + '/'+ form.id + '?rev=' + form.key._rev,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            },
            FORM_DELETE_SUCCESS, FORM_DELETE_FAILURE)
        )
    }
}

export const formDeleteProcessor = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === FORM_DELETE_SUCCESS) {
        dispatch(formGetAll())
        dispatch(formDeleteShowConfirmModal(false, ""))
    }
}

export const formCopySideEffect = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === FORM_COPY) {
        const original = action.payload.key
        const formCopy = {
            type: 'form',
            name: original.name + ' - copy',
            description: original.description + ' - copy',
            field_lastId: original.field_lastId,
            img: original.img,
            fields: original.fields,
            active: false,
            buffer: original.buffer
        }
        dispatch(formCreateStart(formCopy))
    }
}

export const formCopyProcessor = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === FORM_COPY_SUCCESS) {
        dispatch(formGetAll())
    }
}



export const formMiddleware = [
    ///////// TODO //////////// handle createOrUpdateFormCountDoc() action
    formCreateProcessor, // starts off the form creation pipeline
    formGetLastIdSideEffect, // network request to get the last id
    formLastIdProcessor, // check if we have rows and update the last form id
    formGetUuidSideEffect, // network request to get the uuid
    formUuidProcessor, // build the uuid and last form id into a form object
    formAddNewSideEffect, // network request to create the form object
    formGetAllAfterCreated, // pipe to getAll request if successful
    formGetAllSideEffect, // network request to getAll forms
    sendFormListToStore, // save form list
    formUpdateSideEffect, // network request to update form
    formUpdateProcessor,
    formDeleteSideEffect,
    formDeleteProcessor,
    formCopySideEffect,
    formCopyProcessor
];