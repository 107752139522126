import React from 'react';
import {
    CToaster,
    CToast,
    CToastHeader,
    CToastBody
} from '@coreui/react';
import { useSelector } from 'react-redux'
import { TOAST_TYPE_ERROR } from './../constants'

const FLToastContainer = (props) => {
    const toasts = useSelector(state => state.toasts.all)

    return (
        <>
            <CToaster position='bottom-right'>
                {toasts.map((toast, key) => {
                    return <CToast
                        key={'toast' + key}
                        show={true}
                        autohide={5000}
                        fade={true}>
                        <CToastHeader >
                            <div className={toast.type === TOAST_TYPE_ERROR ? 'text-danger' : ''}>{toast.title}</div>
                        </CToastHeader>
                        { toast.type === TOAST_TYPE_ERROR &&
                            <CToastBody>
                                <div className='text-danger'>{toast.message}</div>
                            </CToastBody>}                        
                    </CToast>
                })}
            </CToaster>
        </>
    )
}

export default FLToastContainer;