import { SPINNER_SHOW, SPINNER_HIDE} from './spinner.actions'

const initSpinner = {
    inFlightRequestCount: 0
}

export function spinnerReducer(state = initSpinner, action) {
    switch(action.type) {
        case SPINNER_SHOW:
            return {...state, inFlightRequestCount: state.inFlightRequestCount + 1}
        case SPINNER_HIDE:
            return {...state, inFlightRequestCount: state.inFlightRequestCount - 1}
        default:
            return state
    }
}