export const SPINNER_SHOW = '[Spinner] show'
export const SPINNER_HIDE = '[Spinner] hide'

/////// user actions

export const spinnerShow = () => ({
    type: SPINNER_SHOW
});

export const spinnerHide = () => ({
    type: SPINNER_HIDE
});

/////////// system actions
