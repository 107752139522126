import {API_REQUEST} from "./api.action";
import { spinnerShow, spinnerHide} from './../spinner/spinner.actions'

// this middleware care only for API calls
export const api = ({dispatch}) => next => action => {

    if(action.type === API_REQUEST) {
        const { config, onSuccess, onError } = action.meta;

        dispatch(spinnerShow())

        const relativeUrl = action.payload
        // eslint-disable-next-line
        const absoluteUrl = process.env.REACT_APP_FORESTLINK_BASE_URL + relativeUrl

        fetch(relativeUrl, config)
            .then(response => {
                dispatch(spinnerHide())
                if(!response.ok) {
                    return Promise.reject(response.statusText)
                }
                return response
            })
            .then(response => {
                // any non json type content, just return <empty string>
                // all responses should succeed here because we have already checked response.ok
                return new Promise((resolve, reject) => {
                    try {
                        response.json().then((json) => {
                            return resolve(json)
                        }, (err) => {
                            // non json responses will throw an error. catch and turn into resolved promise, so we can still hit the success handler
                            return resolve('')
                        })
                    } catch(err) {
                        // non json responses will throw an error. catch and turn into resolved promise, so we can still hit the success handler
                        return resolve('')
                    }
                })
            })
            .then((data) => {
                return dispatch({ type: onSuccess, payload: data, meta: action })
            })
            .catch(error => {
                dispatch(spinnerHide())
                return dispatch({ type: onError, payload: error, meta: action })
            })
    }
    return next(action)
};