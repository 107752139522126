import { LOCALITIES_GET_ALL, LOCALITIES_GET_ALL_SUCCESS, LOCALITIES_GET_ALL_FAILURE, LOCALITIES_CREATE, LOCALITIES_CREATE_SUCCESS, LOCALITIES_CREATE_FAILURE, LOCALITIES_EDIT,
    LOCALITIES_EDIT_SUCCESS, LOCALITIES_EDIT_FAILURE, LOCALITIES_DELETE, LOCALITIES_DELETE_SUCCESS, LOCALITIES_DELETE_FAILURE, 
    localitiesUpdateAll, localitiesUpdateObject, localitiesCreateFailure, localitiesUpdateNetworkRequestCompleteFlag, localitiesGetAll, localitiesUpdateParents } from './localities.actions'
import { apiRequest } from './../api/api.action'
import { APP_DB } from './../../constants'

export const localitiesGetAllSideEffect = (store) => next => action => {
    next(action)
    if (action.type === LOCALITIES_GET_ALL) {
        store.dispatch(apiRequest(
            '/' + APP_DB + '/_design/db-app/_view/localities',
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            },
            LOCALITIES_GET_ALL_SUCCESS, LOCALITIES_GET_ALL_FAILURE)
        )
    }
}


export const localitiesGetAllProcessor = (store) => next => action => {
    next(action)
    if (action.type === LOCALITIES_GET_ALL_SUCCESS) {
        const parents = action.payload.rows[0].value.divisions[1].values // where hierarchy = 1
        parents.sort((p1, p2) => {
            if (p1.name.toUpperCase() < p2.name.toUpperCase()) {
                return -1;
            }
            if (p1.name.toUpperCase() > p2.name.toUpperCase()) {
                return 1;
            }
            return 0;
        })
        store.dispatch(localitiesUpdateParents(parents))
        store.dispatch(localitiesUpdateAll(action.payload.rows[0].value.divisions[2].values)) // where hierarchy = 2
        store.dispatch(localitiesUpdateObject(action.payload.rows[0].value))
    }
}


export const localitiesCreateSideEffect = (store) => next => action => {
    next(action)
    if (action.type === LOCALITIES_CREATE) {
        const editObject = store.getState().localities.editObject
        const id = editObject._id
        const newLocality = action.payload
        const existingLocalities = editObject.divisions[2].values
        if (isDuplicate(newLocality, existingLocalities)) {
            store.dispatch(localitiesCreateFailure('Can not create duplicate locality'))
        } else {
            existingLocalities.push(newLocality)
            store.dispatch(apiRequest(
                '/' + APP_DB + '/' + id,
                {
                    method: 'PUT',
                    body: JSON.stringify(editObject),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                },
                LOCALITIES_CREATE_SUCCESS, LOCALITIES_CREATE_FAILURE)
            )
        }


        function isDuplicate(newLocality, existingLocalities) {
            const sameLocality = existingLocalities.find(loc => loc.name === newLocality.name && loc.parent === newLocality.parent)
            return (typeof sameLocality !== 'undefined')
        }
    }
}

export const localitiesCreateProcessor = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === LOCALITIES_CREATE_SUCCESS) {
        dispatch(localitiesUpdateNetworkRequestCompleteFlag(true))
        dispatch(localitiesGetAll())
    }
}

export const localitiesEditSideEffect = (store) => next => action => {
    next(action)
    if (action.type === LOCALITIES_EDIT) {
        const editObject = store.getState().localities.editObject
        const id = editObject._id
        const editableLocality = action.payload
        const existingLocalities = editObject.divisions[2].values
        if (isDuplicate(editableLocality, existingLocalities)) {
            store.dispatch(localitiesCreateFailure('Can not create duplicate locality'))
        } else {
            // remove original
            const otherLocalities = existingLocalities.filter(locality => {
                const same = editableLocality.original.name === locality.name && editableLocality.original.parent === locality.parent
                return !same
            })
            // add updated locality
            otherLocalities.push(editableLocality.edited)
            // merge back into edit object
            editObject.divisions[2].values = otherLocalities
            store.dispatch(apiRequest(
                '/' + APP_DB + '/' + id,
                {
                    method: 'PUT',
                    body: JSON.stringify(editObject),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                },
                LOCALITIES_EDIT_SUCCESS, LOCALITIES_EDIT_FAILURE)
            )
        }


        function isDuplicate(editableLocality, existingLocalities) {
            // remove original locality we are editing
            const untouchedLocalities = existingLocalities.filter(locality => {
                const same = editableLocality.original.name === locality.name && editableLocality.original.parent === locality.parent
                return !same
            })
            const sameLocality = untouchedLocalities.find(loc => loc.name === editableLocality.edited.name && loc.parent === editableLocality.edited.parent)
            return (typeof sameLocality !== 'undefined')
        }
    }
}

export const localitiesEditProcessor = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === LOCALITIES_EDIT_SUCCESS) {
        dispatch(localitiesUpdateNetworkRequestCompleteFlag(true))
        dispatch(localitiesGetAll())
    }
}

export const localitiesDeleteSideEffect = (store) => next => action => {
    next(action)
    if (action.type === LOCALITIES_DELETE) {
        const editObject = store.getState().localities.editObject
        const id = editObject._id
        const deleteLocality = action.payload
        const existingLocalities = editObject.divisions[2].values
        // remove delete locality
        const remainingLocalities = existingLocalities.filter(locality => {
            const same = deleteLocality.name === locality.name && deleteLocality.parent === locality.parent
            return !same
        })
        // update object
        editObject.divisions[2].values = remainingLocalities
        
        store.dispatch(apiRequest(
            '/' + APP_DB + '/' + id,
            {
                method: 'PUT',
                body: JSON.stringify(editObject),
                headers: {
                    'Content-Type': 'application/json'
                }
            },
            LOCALITIES_DELETE_SUCCESS, LOCALITIES_DELETE_FAILURE)
        )
    }
}

export const localitiesDeleteProcessor = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === LOCALITIES_DELETE_SUCCESS) {
        dispatch(localitiesGetAll())
    }
}


export const localitiesMiddleware = [
    localitiesGetAllSideEffect,
    localitiesGetAllProcessor,
    localitiesCreateSideEffect,
    localitiesCreateProcessor,
    localitiesEditSideEffect,
    localitiesEditProcessor,
    localitiesDeleteSideEffect,
    localitiesDeleteProcessor
]