import { FILE_UPLOAD_UPDATE_CURRENT_UPLOAD, FILE_UPLOAD_CLEAR_CURRENT_UPLOAD } from './fileUpload.actions'
var _ = require('lodash');

const initFileUpload = {
    current: {},
}

export function fileUploadReducer(state = initFileUpload, action) {
    switch(action.type) {
        case FILE_UPLOAD_UPDATE_CURRENT_UPLOAD:
            const newState = {...state}
            const clone = _.cloneDeep(action.payload.upload)
            clone.file = action.payload.upload.file
            newState.current[action.payload.uploadId] = clone
            return newState
        case FILE_UPLOAD_CLEAR_CURRENT_UPLOAD:
            return {...state, current: {}}
        default:
            return state
    }
}