import { GEO_GET_DATA, GEO_GET_DATA_SUCCESS, GEO_GET_DATA_FAILURE, GEO_GET_SOURCES, GEO_GET_SOURCES_SUCCESS, GEO_GET_SOURCES_FAILURE, 
    geoUpdateData, geoUpdateSources, geoGetData } from './geo.actions'
import { apiRequest } from './../api/api.action'
import { getGeo } from './geo.selector'
import { APP_DB } from './../../constants'

export const getGeoSourcesSideEffect = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === GEO_GET_SOURCES) {
        const existingGeoData = getState().geo.sources
        if (existingGeoData.length === 0) {
            dispatch(apiRequest(
                '/' + APP_DB + '/_design/db-app/_view/layers',
                { },
                GEO_GET_SOURCES_SUCCESS, GEO_GET_SOURCES_FAILURE)
            )
        } else {} // we already have data to do nothing
    }
}

export const geoGetSourcesProcessor = (store) => next => action => {
    next(action)
    if (action.type === GEO_GET_SOURCES_SUCCESS) {
        store.dispatch(geoUpdateSources(action.payload.rows[0].key.values))

        // get the layers data from the geo end points straight away
        store.getState().geo.sources.forEach(source => {
            store.dispatch(geoGetData(source.id))
        })
    }
}


export const getGeoDataSideEffect = (store) => next => action => {
    next(action)
    if (action.type === GEO_GET_DATA) {
        const mySource = getGeo(action.payload)(store.getState())
        if (!mySource.flGeoData) { // only fetch if we dont already have it
            store.dispatch(apiRequest(
                mySource.url,
                { },
                GEO_GET_DATA_SUCCESS, GEO_GET_DATA_FAILURE, action.payload)
            )
        } // else do nothing
    }
}


export const geoGetDataProcessor = (store) => next => action => {
    next(action)
    if (action.type === GEO_GET_DATA_SUCCESS) {
        const sourceId = action.meta.meta.successData
        store.dispatch(geoUpdateData(sourceId, action.payload))
    }
}

export const geoMiddleware = [
    getGeoSourcesSideEffect,
    geoGetSourcesProcessor,
    getGeoDataSideEffect,
    geoGetDataProcessor
]