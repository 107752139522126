import { GEO_UPDATE_SOURCES, GEO_UPDATE_DATA } from './geo.actions'

const initGeo = {
    sources: []
}

export function geoReducer(state = initGeo, action) {
    switch(action.type) {
        case GEO_UPDATE_SOURCES:
            return {...state, sources: action.payload}
        case GEO_UPDATE_DATA:
            return {...state, sources: state.sources.map(source => {
                if (source.id === action.payload.sourceId) {
                    return {...source, flGeoData: action.payload.geoData}
                } else {
                    return source
                }
            })}
        default:
            return state
    }
}