export const GEO_GET_SOURCES = '[Geo] get sources'
export const GEO_GET_SOURCES_SUCCESS = '[Geo] get sources success'
export const GEO_GET_SOURCES_FAILURE = '[Geo] get sources failure'
export const GEO_UPDATE_SOURCES = '[Geo] update sources'


export const GEO_GET_DATA = '[Geo] get data'
export const GEO_GET_DATA_SUCCESS = '[Geo] get data success'
export const GEO_GET_DATA_FAILURE = '[Geo] get data failure'
export const GEO_UPDATE_DATA = '[Geo] update data'

export const GEO_UPDATE_SOURCE_VISIBLE = '[Geo] update source visible'

///// user defined actions

export const geoGetSources = () => ({
    type: GEO_GET_SOURCES,
});

export const geoGetData = (sourceId) => ({
    type: GEO_GET_DATA,
    payload: sourceId
});

export const geoUpdateSourceVisible = (sourceId, visible) => ({
    type: GEO_UPDATE_SOURCE_VISIBLE,
    payload: {
        id: sourceId,
        visible
    }
});

///// system actions

export const geoUpdateSources = (sourceData) => ({
    type: GEO_UPDATE_SOURCES,
    payload: sourceData
});

export const geoUpdateData = (sourceId, geoData) => ({
    type: GEO_UPDATE_DATA,
    payload: {
        sourceId,
        geoData
    }
});

