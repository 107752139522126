export const FORM_CREATE_START = '[Form] create start'

export const FORM_GET_LAST_ID = '[Form] get last id'
export const FORM_GET_LAST_ID_SUCCESS = '[Form] get last id success'
export const FORM_GET_LAST_ID_FAILURE = '[Form] get last id failure'

export const FORM_GET_UUID = '[Form] get uuid'
export const FORM_UUID_SUCCESS = '[Form] uuid success'
export const FORM_UUID_FAILURE = '[Form] uuid failure'

export const FORM_ADD_NEW = '[Form] add new'
export const FORM_ADD_NEW_SUCCESS = '[Form] add new success'
export const FORM_ADD_NEW_FAILURE = '[Form] add new failure'

export const FORM_GET_ALL = '[Form] get all'
export const FORM_GET_ALL_SUCCESS = '[Form] get all success'
export const FORM_GET_ALL_FAILURE = '[Form] get all failure'


export const FORM_GEN_PROTO = '[Form] generate proto'
export const FORM_GEN_PROTO_SUCCESS = '[Form] generate proto success'
export const FORM_GEN_PROTO_FAILURE = '[Form] generate proto failure'

export const FORM_UPDATE = '[Form] update'
export const FORM_UPDATE_SUCCESS = '[Form] update success'
export const FORM_UPDATE_FAILURE = '[Form] update failure'

export const FORM_DELETE = '[Form] delete'
export const FORM_DELETE_SUCCESS = '[Form] delete success'
export const FORM_DELETE_FAILURE = '[Form] delete failure'

export const FORM_DELETE_CONFIRM_MODAL = '[Alert] delete form confirmation modal'

export const FORM_COPY = '[Form] copy'
export const FORM_COPY_SUCCESS = '[Form] copy success'
export const FORM_COPY_FAILURE = '[Form] copy failure'

export const FORM_UPDATE_LIST = '[Form] update list'

export const FORM_OBJECT_CREATED_EVENT = '[Form] object created event'

export const UPDATE_LAST_FORM_ID = '[Form] update last from id'

export const FORM_CREATE_OR_UPDATE_COUNT_DOC = '[Form] create or update count doc'

export const FORM_UPDATE_NEW_FORM_TO_CREATE = '[Form] update new form to create'

export const FORM_UPDATE_NETWORK_REQUEST_COMPLETE_FLAG = '[Form] update network request complete flag'


////// user actions

export const formCreateStart = (newForm) => ({
    type: FORM_CREATE_START,
    payload: newForm
});

export const formGetAll = () => ({
    type: FORM_GET_ALL
});

export const formUpdate = (form) => ({
    type: FORM_UPDATE,
    payload: form
});

export const formGenProto = (form) => ({
    type: FORM_GEN_PROTO,
    payload: form
});

export const formDeleteShowConfirmModal = (show, form) => ({
        type: FORM_DELETE_CONFIRM_MODAL,
        payload: {
            show,
            form
        }
});

export const formDelete = (form) => ({
    type: FORM_DELETE,
    payload: form
});

export const formCopy = (form) => ({
    type: FORM_COPY,
    payload: form
});


//////// internal actions

export const formGetLastId = () => ({
    type: FORM_GET_LAST_ID
});

export const formGetUuid = (key) => ({
    type: FORM_GET_UUID,
    payload: key
});

export const formAddNew = (form) => ({
    type: FORM_ADD_NEW,
    payload: form
});

export const formUpdateList = (formList) => ({
    type: FORM_UPDATE_LIST,
    payload: formList
});

export const updateLastFormId = (lastFormId) => ({
    type: UPDATE_LAST_FORM_ID,
    payload: lastFormId
});

export const createOrUpdateFormCountDoc = () => ({
    type: FORM_CREATE_OR_UPDATE_COUNT_DOC
});

export const formUpdateNewFormToCreate = (newForm) => ({
    type: FORM_UPDATE_NEW_FORM_TO_CREATE,
    payload: newForm
});

export const formUpdateNetworkRequestCompleteFlag = (success) => ({
    type: FORM_UPDATE_NETWORK_REQUEST_COMPLETE_FLAG,
    payload: success
});