export const getCurrentFileUpload = (uploadId) => {
    return (state) => {
        return state.fileUpload.current[uploadId]
    }
}

export const getAllFileUploads = (state) => {
    return state.fileUpload.current
}

export const getFileUploadStatus = (state) => {
    const uploadArray = Object.keys(state.fileUpload.current).map((uploadId) => { return state.fileUpload.current[uploadId]})
    const allComplete = uploadArray.every( upload => upload.complete )
    const anySuccessful = uploadArray.some( upload => upload.successful)
    const failedUploads = uploadArray.filter(upload => !upload.successful)
    const S3Paths = uploadArray.filter(upload => upload.complete)
                        .filter(upload => upload.successful )
                        .map((upload) => upload.S3Path)
    const types = uploadArray.map((upload) => upload.type)
    const allSameType = types.every( type => type === types[0] )
    if ( !allSameType ) throw new Error('File uploads of different types happening at the same time')


    // none of the uploads may have an alert attached.
    // E.g. for images we have to request the relevant alert from the back end, but the back end
    // may not find any alert if the image name format is wrong
    let alert
    if (allComplete && anySuccessful) {
        const successfulUploads = uploadArray.filter(upload => upload.complete)
                                        .filter(upload => upload.successful)
        const alertIds = successfulUploads.map((upload) => upload.alert._id)
        const allSameAlert = alertIds.every( alertId => alertId === alertIds[0] )
        if ( !allSameAlert ) throw new Error('File uploads to different alerts happening at the same time')
        alert = successfulUploads[0].alert
    }

    return {
        started: uploadArray.length > 0,  // at least one upload in flight
        complete: allComplete,
        anySuccessful,
        type: types[0],
        alert, // can be undefined, so only read if allComplete and anySuccessful
        S3Paths, // paths for all successful uploads
        failedUploads
    }
}