export const LOCALITIES_GET_ALL = '[Localities] get all'
export const LOCALITIES_GET_ALL_SUCCESS = '[Localities] get all success'
export const LOCALITIES_GET_ALL_FAILURE = '[Localities] get all failure'
export const LOCALITIES_UPDATE_ALL = '[Localities] update all'

// There is no action to trigger the network request to get parents because we use
// the data returned as part of LOCALITIES_GET_ALL
export const LOCALITIES_UPDATE_PARENTS = '[Localities] update parents'

export const LOCALITIES_UPDATE_OBJECT = '[Localities] update object'

export const LOCALITIES_CREATE = '[Localities] create'
export const LOCALITIES_CREATE_SUCCESS = '[Localities] create success'
export const LOCALITIES_CREATE_FAILURE = '[Localities] create failure'

export const LOCALITIES_EDIT = '[Localities] edit'
export const LOCALITIES_EDIT_SUCCESS = '[Localities] edit success'
export const LOCALITIES_EDIT_FAILURE = '[Localities] edit failure'

export const LOCALITIES_DELETE = '[Localities] delete'
export const LOCALITIES_DELETE_SUCCESS = '[Localities] delete success'
export const LOCALITIES_DELETE_FAILURE = '[Localities] delete failure'

export const LOCALITIES_UPDATE_NETWORK_REQUEST_COMPLETE_FLAG = '[Localities] update network request complete flag'

//////// user actions

export const localitiesGetAll = () => ({
    type: LOCALITIES_GET_ALL
});

export const localitiesCreate = (locality) => ({
    type: LOCALITIES_CREATE,
    payload: locality
});

export const localitiesEdit = (locality) => ({
    type: LOCALITIES_EDIT,
    payload: locality
});

export const localitiesDelete = (locality) => ({
    type: LOCALITIES_DELETE,
    payload: locality
});

export const localitiesUpdateNetworkRequestCompleteFlag = (requestComplete) => ({
    type: LOCALITIES_UPDATE_NETWORK_REQUEST_COMPLETE_FLAG,
    payload: requestComplete
});


///// system actions

export const localitiesUpdateObject = (myObject) => ({
    type: LOCALITIES_UPDATE_OBJECT,
    payload: myObject
})

export const localitiesUpdateAll = (localities) => ({
    type: LOCALITIES_UPDATE_ALL,
    payload: localities
});

export const localitiesUpdateParents = (parents) => ({
    type: LOCALITIES_UPDATE_PARENTS,
    payload: parents
});

export const localitiesCreateFailure = (message) => ({
    type: LOCALITIES_CREATE_FAILURE,
    payload: message
});