import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import { LOGOUT } from './login/login.actions'
import { logger } from './logger.middleware'
import { loginMiddleware } from './login/login.middleware'
import { api } from './api/api.middleware'
import { uiReducer } from './ui.reducer'
import { loginReducer } from './login/login.reducer'
import { formReducer } from './form/form.reducer'
import { formMiddleware } from './form/form.middleware'
import { formEditMiddleware } from './form/formEdit.middleware'
import { formEditReducer } from './form/formEdit.reducer'
import { toastMiddleware } from './toast/toast.middleware'
import { toastReducer } from './toast/toast.reducer'
import { errorThrownProcessor } from './errors/errors.middleware'
import { alertMiddleware } from './alert/alert.middleware'
import { alertReducer } from './alert/alert.reducer'
import { geoReducer } from './geo/geo.reducer'
import { geoMiddleware } from './geo/geo.middleware'
import { userReducer } from './users/users.reducer'
import { usersMiddleware } from './users/users.middleware'
import { localitiesReducer } from './localities/localities.reducer'
import { localitiesMiddleware } from './localities/localities.middleware'
import { fileUploadReducer } from './fileUpload/fileUpload.reducer'
import { fileUploadMiddleware } from './fileUpload/fileUpload.middleware'
import { statisticsMiddleware } from './statistics/statistics.middleware'
import { statisticsReducer } from './statistics/statistics.reducer'
import { spinnerReducer } from './spinner/spinner.reducer'

const appReducer = combineReducers({
    ui: uiReducer,
    login: loginReducer,
    formList: formReducer,
    editForm: formEditReducer,
    toasts: toastReducer,
    alerts: alertReducer,
    geo: geoReducer,
    users: userReducer,
    localities: localitiesReducer,
    fileUpload: fileUploadReducer,
    statistics: statisticsReducer,
    spinner: spinnerReducer
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined
    }
    return appReducer(state, action)
}

const myMiddleware = applyMiddleware(
    ...loginMiddleware,
    ...formMiddleware,
    ...formEditMiddleware,
    ...alertMiddleware,
    ...geoMiddleware,
    ...usersMiddleware,
    ...localitiesMiddleware,
    ...fileUploadMiddleware,
    ...statisticsMiddleware,
    logger,
    api,
    ...toastMiddleware,
    errorThrownProcessor
)

// https://github.com/reduxjs/redux-devtools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(
    myMiddleware,
    // other store enhancers,
    // other redux middleware
))
export default store