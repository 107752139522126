export const USERS_GET_ALL = '[Users] get all'
export const USERS_GET_ALL_SUCCESS = '[Users] get all success'
export const USERS_GET_ALL_FAILURE = '[Users] get all failure'
export const USERS_UPDATE_ALL = '[Users] update all'

export const USERS_CREATE = '[Users] create'
export const USERS_CREATE_SUCCESS = '[Users] create success'
export const USERS_CREATE_FAILURE = '[Users] create failure'

export const USERS_EDIT = '[Users] edit'
export const USERS_EDIT_SUCCESS = '[Users] edit success'
export const USERS_EDIT_FAILURE = '[Users] edit failure'

export const USERS_DELETE_SHOW_CONFIRM_MODAL = "[Users] show delete modal"

export const USERS_DELETE = '[Users] delete'
export const USERS_DELETE_SUCCESS = '[Users] delete success'
export const USERS_DELETE_FAILURE = '[Users] delete failure'

export const USERS_UPDATE_NETWORK_REQUEST_COMPLETE_FLAG = '[Users] update network requeat complete flag'

//////// users defined actions

export const usersGetAll = () => ({
    type: USERS_GET_ALL
});

export const usersCreate = (user) => ({
    type: USERS_CREATE,
    payload: user
});

export const usersEdit = (user) => ({
    type: USERS_EDIT,
    payload: user
});

export const userDeleteShowConfirmModal = (show, userId) => ({
    type: USERS_DELETE_SHOW_CONFIRM_MODAL,
    payload: {
        show,
        userId
    }
});

export const usersDelete = (userId) => ({
    type: USERS_DELETE,
    payload: userId
});

export const usersUpdateNetworkRequestCompleteFlag = (success) => ({
    type: USERS_UPDATE_NETWORK_REQUEST_COMPLETE_FLAG,
    payload: success
});

//////// internal actions

export const usersUpdateAll = (users) => ({
    type: USERS_UPDATE_ALL,
    payload: users
});