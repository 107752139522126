export const FORMEDIT_CREATE_EDITABLE = '[Form Edit] create editable form'

export const FORMEDIT_SAVE_FORM = '[Form Edit] save form'

export const FORMEDIT_TRANSFORM_TO_FORM = '[Form Edit] transform tree back to form'

export const FORMEDIT_NON_EXISTENT_FORM_SELECTED = '[Form Edit] non existent form selected'

export const FORMEDIT_UPDATE_CURRENTLY_EDITING = '[Form Edit] update currently editing'

export const FORMEDIT_UPDATE_CURRENTLY_EDITING_ID = '[Form Edit] update currently editing id'

export const FORMEDIT_TREE_TRANSFORMATION_COMPLETE = '[Form Edit] tree transformation complete'

export const FORMEDIT_FORM_TRANSFORMATION_COMPLETE = '[Form Edit] form transformation complete'

export const FORMEDIT_CREATE_NEW_NODE = '[Form Edit] create new node'

export const FORMEDIT_DELETE_NODE = '[Form Edit] delete node'

export const FORMEDIT_UPDATE_CURRENT_OPTION_LIST = '[Form Edit] update current option list'

export const FORMEDIT_UPDATE_OPTION_SELECTED = '[Form Edit] update option selected'

export const FORMEDIT_UPDATE_QUESTION = '[Form Edit] update question'

export const FORMEDIT_UPDATE_CURRENT_TITLE_AND_DESCRIPTION = '[Form Edit] update current title and description'

export const FORMEDIT_GENERATE_PROTOBUF = '[Form Edit] generate protocol buffer'

////// user actions

export const formEditCreateEditableForm = (formId) => ({
    type: FORMEDIT_CREATE_EDITABLE,
    payload: formId
});

export const formEditSaveForm = () => ({
    type: FORMEDIT_SAVE_FORM
});

export const formEditGenerateProtoBuf = () => ({
    type: FORMEDIT_GENERATE_PROTOBUF
});

export const formEditCreateNewNode = (nodePathAndType) => ({
    type: FORMEDIT_CREATE_NEW_NODE,
    payload: nodePathAndType
});

export const formEditDeleteNode = (nodePath) => ({
    type: FORMEDIT_DELETE_NODE,
    payload: nodePath
});

export const formEditUpdateOptionSelected = (newOptionsSelected) => ({
    type: FORMEDIT_UPDATE_OPTION_SELECTED,
    payload: newOptionsSelected
})

export const formEditUpdateQuestion = (path, value) => ({
    type: FORMEDIT_UPDATE_QUESTION,
    payload: {
        path,
        value
    }
})

export const formEditUpdateTitleAndDescription = (title, description) => ({
    type: FORMEDIT_UPDATE_CURRENT_TITLE_AND_DESCRIPTION,
    payload: { title, description }
})

//////// internal actions

export const formEditNonExistentFormSelected = (formId) => ({
    type: FORMEDIT_NON_EXISTENT_FORM_SELECTED,
    payload: formId
});

export const formEditUpdateCurrentlyEditing = (formTree) => ({
    type: FORMEDIT_UPDATE_CURRENTLY_EDITING,
    payload: formTree
});

export const formEditUpdateCurrentlyEditingId = (formId) => ({
    type: FORMEDIT_UPDATE_CURRENTLY_EDITING_ID,
    payload: formId
});

export const formEditTransformToForm = (tree) => ({
    type: FORMEDIT_TRANSFORM_TO_FORM,
    payload: tree
});

export const formEditTransformToTreeComplete = (beforeAndAfter) => ({
    type: FORMEDIT_TREE_TRANSFORMATION_COMPLETE,
    payload: beforeAndAfter
});

export const formEditTransformToFormComplete = (beforeAndAfter) => ({
    type: FORMEDIT_FORM_TRANSFORMATION_COMPLETE,
    payload: beforeAndAfter
});

export const formEditUpdateCurrentOptionList = (optionNodesEnabled) => ({
    type: FORMEDIT_UPDATE_CURRENT_OPTION_LIST,
    payload: optionNodesEnabled
})
