import { USERS_UPDATE_ALL, USERS_UPDATE_NETWORK_REQUEST_COMPLETE_FLAG, USERS_DELETE_SHOW_CONFIRM_MODAL } from './users.actions'

const initUser = {
    all: [],
    successfulRequest: false, // used to redirect from user edit and create pages after a successful user upload. Navigate to user list
    showDeleteUserConfirmModal:false,
    userToBeDeleted: ""
}

export function userReducer(state = initUser, action) {
    switch(action.type) {
        case USERS_UPDATE_ALL:
            return {...state, all: action.payload}
        case USERS_UPDATE_NETWORK_REQUEST_COMPLETE_FLAG:
            return {...state, successfulRequest: action.payload}
        case USERS_DELETE_SHOW_CONFIRM_MODAL:
            return {...state, showDeleteUserConfirmModal: action.payload.show, userToBeDeleted: action.payload.userId}
        default:
            return state
    }
}