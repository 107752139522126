import { toastUpdateMessages } from './toast.actions'
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from './../../constants'

export const processAllFailureActions = ({dispatch}) => next => action => {
    if(action.type.toLowerCase().includes('failure') || action.type.toLowerCase().includes('error')) {
        let message
        if (typeof action.payload === 'string' || action.payload instanceof String) {
            message = action.payload
        } else if (typeof action.payload.message === 'string' || action.payload.message instanceof String) {
            message = action.payload.message
        } else {
            message = JSON.stringify(action.payload)
        }
        dispatch(toastUpdateMessages(action.type, message, TOAST_TYPE_ERROR))
    }
    return next(action)
};

export const processAllSuccessActions = ({dispatch}) => next => action => {
    if(action.type.includes('success')) {
        dispatch(toastUpdateMessages(action.type, '', TOAST_TYPE_SUCCESS))
    }
    return next(action)
};


// uncomment to see toast success/error messages at the bottom right of all pages
export const toastMiddleware = [
    // processAllFailureActions
    // ,
    // processAllSuccessActions
]