import { enGB, es, fr } from 'date-fns/locale';

//////////////// Form Tree ////////////////////

export const NODETYPE_SELECTONE = 'selectOne'
export const NODETYPE_SELECTMANY = 'selectMany'
export const NODETYPE_TEXT = 'text'
export const NODETYPE_NUMBER = 'number'
export const NODETYPE_GEO = 'geo'
export const NODETYPE_IMAGE = 'image'
// back end form structure does not have a node of type OPTION
// but the UI tree does
export const NODETYPE_OPTION = 'option'


////////////// Toasts ////////////////

export const TOAST_TYPE_ERROR = 'toast_type_error'
export const TOAST_TYPE_SUCCESS = 'toast_type_success'



/////////////////// File Upload //////////////

// strings required by the back end in fileUploadRequestUrlSideEffect() method
export const FILE_UPLOAD_CATEGORY_IMAGES = 'images'
export const FILE_UPLOAD_CATEGORY_ATTACHMENTS = 'attachments'

// components which request the file upload. Will determine the steps to take after uplaod
export const FILE_UPLOAD_TYPE_IMAGE = 'image'
export const FILE_UPLOAD_TYPE_VERIFICATION = 'verification'
export const FILE_UPLOAD_TYPE_FOLLOWUP = 'followup'

export const S3_DOWNLOAD_LINK_PREFIX = 'https://rtm-evidences.s3.eu-west-2.amazonaws.com/'


/////////////////////// alert filters
export const SENT_VIA_INTERNET = 'INTERNET'
export const SENT_VIA_SMS = 'SMS'
export const SENT_VIA_SATELLITE = 'SATELLITE'


/////////////////////// language coming from .env file
export const APP_LANGUAGE = process.env.REACT_APP_LANGUAGE // 2 letter, either gb, fr or es

let myLocale
switch(APP_LANGUAGE) {
    case 'gb':
        myLocale = enGB
        break
    case 'es':
        myLocale = es
        break
    case 'fr':
        myLocale = fr
        break
    default:
        myLocale = enGB
}

export const APP_LOCALE = myLocale // equivalent locale to the language. Use for dates format

export const APP_ORG = process.env.REACT_APP_FORESTLINK_ORG
export const APP_COUNTRY = process.env.REACT_APP_FORESTLINK_COUNTRY
export const APP_DB = process.env.REACT_APP_FORESTLINK_DB
export const APP_ORG_NAME = process.env.REACT_APP_FORESTLINK_ORG_NAME
export const APP_FLAG = process.env.REACT_APP_FORESTLINK_FLAG
export const APP_LOGO = process.env.REACT_APP_FORESTLINK_LOGO
export const APP_PARTNER = process.env.REACT_APP_FORESTLINK_PARTNER
export const APP_PARTNER_2 = process.env.REACT_APP_FORESTLINK_PARTNER_2
export const APP_PARTNER_3 = process.env.REACT_APP_FORESTLINK_PARTNER_3
export const APP_PARTNER_4 = process.env.REACT_APP_FORESTLINK_PARTNER_4
export const APP_DB_URL = process.env.REACT_APP_FORESTLINK_DB_URL
export const APP_COUNTRY_LAT = process.env.REACT_APP_FORESTLINK_COUNTRY_LAT
export const APP_COUNTRY_LONG = process.env.REACT_APP_FORESTLINK_COUNTRY_LONG
export const APP_COUNTRY_ZOOM = process.env.REACT_APP_FORESTLINK_COUNTRY_ZOOM


/////////////////////////// users roles
export const ROLE_COORDINATOR = APP_DB+'_coordinator'
export const ROLE_ADMIN = APP_DB+'_administration'
export const ROLE_VERIFICATOR = APP_DB+'_verificator'
